import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faShareAlt, faCopy, faTimes, faCommentSms } from '@fortawesome/free-solid-svg-icons';
import css from './SectionShareMaybe.module.css';
import copy from 'copy-to-clipboard';

const SectionShareMaybe = ({ title, listing, onCreateShortlink }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [bitlyUrl, setBitlyUrl] = useState('');

  const handleShareClick = async () => {
    setIsOpen(true);

    if (listing.attributes.publicData.shortlink) {
      setBitlyUrl(listing.attributes.publicData.shortlink);
    } else {
      let longLink = window.location.href;
      if (longLink.includes('localhost')) {
        const path = window.location.pathname.substring(1);
        longLink = `https://inpink-stage.com/${path}`;
      }

      const response = await onCreateShortlink(listing.id.uuid, longLink);
      setBitlyUrl(response);
    }
  };

  const handleCloseClick = () => {
    setIsOpen(false);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(bitlyUrl);
    alert('Link copied to clipboard!');
  };

  const handleSMSClick = () => {
    const message = `Check out this listing on INPINK! ${bitlyUrl}`;
    const smsLink = `sms:&body=${encodeURIComponent(message)}`;

    window.location.href = smsLink;
  };

  return (
    <div>
      <button className={css.sharebtn} onClick={handleShareClick}>
        <FontAwesomeIcon icon={faShareAlt} /> Share
      </button>
      <br />
      {isOpen && (
        <div className={css.modal}>
          <div className={css.modalcontent}>
            <button className={css.closebtn} onClick={handleCloseClick}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h2>Share "{title}"</h2>
            <ul>
              <li>
                <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(bitlyUrl)}`}>
                  <FontAwesomeIcon icon={faTwitter} /> Share on Twitter
                </a>
              </li>
              <li>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    bitlyUrl
                  )}`}
                >
                  <FontAwesomeIcon icon={faFacebook} /> Share on Facebook
                </a>
              </li>
              <li>
  <a
    href={`https://www.instagram.com/`}
    target="_blank"
    rel="noopener noreferrer"
  >
      <button onClick={handleCopyClick}>
    <FontAwesomeIcon icon={faInstagram} /> Share on Instagram
  </button>
  </a>
</li>



              <li>
                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                    bitlyUrl
                  )}`}
                >
                  <FontAwesomeIcon icon={faLinkedin} /> Share on LinkedIn
                </a>
              </li>
              {/* <li>
                <button onClick={handleSMSClick}>
                  <FontAwesomeIcon icon={faCommentSms} /> Share via SMS
                </button>
              </li> */}
              <li>
                <button onClick={handleCopyClick}>
                  <FontAwesomeIcon icon={faCopy} /> Copy Link
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionShareMaybe;
