import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionYoutubeMaybe = props => {
  const { youtube, listingTitle } = props;
  const links = youtube.replace(/\s+/g, '').split(',');
  return youtube ? (

    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.youtubeTitle" values={{ listingTitle }} />
      </h2>
      { links.map(link => {
        const linkBreaker = link.split('/');
        const slug = linkBreaker[3] === 'watch' || linkBreaker[3] === 'embed' ? linkBreaker[4] :
         linkBreaker[2] === 'youtu.be' ? linkBreaker[3] : link.split('v=')[1];
        const fullLink = 'https://www.youtube.com/embed/'+slug+'?rel=0';
        return (
        <div className={css.youtubeVideo}>
          
          <iframe width="560" height="315" src={fullLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      )})

      }
    </div>
  ) : null;
};

export default SectionYoutubeMaybe;
